<template>
  <div class="container-fluid">
    <div class="row my-3">
      <h1 class="col-sm-12 col-lg-8">Histórico</h1>
      <div class="col-sm-10 col-lg-3">
        <form @submit.prevent="buscar_solicitud">
          <div class="input-group mb-3">
            <input v-model="buscar" type="text" class="form-control" placeholder="Número solicitud" aria-label="Número solicitud" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <button class="btn btn-secondary">Buscar</button>
            </div>
          </div>
        </form>
      </div>
      <div class="com-sm-2">
        <button class="btn btn-secondary" @click="obtener_comites">Actualizar</button>
      </div>
    </div>

    <Paginacion :data="raw_comite" @paginado="paginacion_refrescar" />

    <div v-if="comites.length > 0" class="comites">
      <div v-for="comite in comites" class="comite">
        <div class="cabecera">
          <div class="numero"><span class="title">Comité número:</span><span class="value">{{ comite.numero }}</span></div>
          <div class="fecha_inicio"><span class="title">Fecha inicio:</span><span class="value">{{ $moment(comite.created_at).format('MMMM DD [del] YYYY') }}</span></div>
          <div class="fecha_fin"><span class="title">Fecha fin:</span><span class="value">{{ comite.finalizado_at ? $moment(comite.finalizado_at).format('MMMM DD [del] YYYY') : '--' }}</span></div>
          <div class="num_solicitudes"><span class="title">Número de solicitudes:</span><span class="value">{{ comite.solicitudes.length }}</span></div>
          <div v-if="$auth.can('analyticpoint','usuario_admin')" class="minuta"><span class="title">Solicitar minuta:</span><span><button @click="solicitar_minuta(comite)" class="btn btn-secondary"><i class="fa-solid fa-inbox"></i></button></span></div>
          <div :class="'accion'+class_solicitudes_show(comite)"><i @click="comite_visible=comite.id" class="fa-solid fa-chevron-down"></i></div>
        </div>
        <template v-if="comite.solicitudes.length == 0">
          <div v-if="comite_visible == comite.id" class="solicitudes text-center">No hay solicitudes en este comité</div>
        </template>
        <template v-else>
          <div v-if="comite_visible == comite.id" class="solicitudes">
            <div v-for="solicitud in comite.solicitudes" :class="'solicitud'+definir_estatus_solicitud(solicitud)">
              <div class="id_solicitd"><strong>Solicitud ID:</strong><br/>{{ solicitud.solicitud_id }}</div>
              <div class="nombre"><strong>Nombre:</strong><br/>{{ solicitud.nombre }}</div>
              <div class="monto"><strong>Monto:</strong><br/>${{ $helper.moneyFormat(solicitud.monto_final) }}</div>
              <div class="tasa"><strong>Tasa:</strong><br/>{{ solicitud.tasa_interes }}%</div>
              <div class="plazo"><strong>Plazo:</strong><br/>{{ solicitud.plazo_final }}</div>
              <div class="aforo"><strong>Aforo:</strong><br/>{{ solicitud.aforo_final }}</div>
              <div class="resolucion"><strong>Resolución:</strong><br/>{{ solicitud.resolucion ? solicitud.resolucion : '--' }}</div>
              <div class="control"><router-link :to="{name: 'analyticpoint-historico-detalle', params:{solicitud: solicitud.id}}" class="btn btn-light">Revisar</router-link></div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="comites">
      <div class="row">
        <div class="col-sm-12 text-center">No hay comites en historico</div>
      </div>
    </div>

    <Paginacion :data="raw_comite" @paginado="paginacion_refrescar" />

  </div>
</template>

<script>
import api from '@/apps/analyticpoint/api/comite/comite';

import Paginacion from '@/apps/analyticpoint/components/Paginacion.vue';

export default {
  components: {
    Paginacion
  },
  data() {
    return {
      comites: [],
      buscar: null,
      comite_visible: null,
      comites_pagina: 10,
      raw_comite: {
        data: [],
        total: 0,
        per_page: 20,
        current_page: 1
      },
      options: {
        page: 1,
        order_col: 'id',
        order_dir: 'desc',
        limit: 20
      }
    }
  },
  mounted() {
    this.obtener_comites();
  },
  methods: {
    async obtener_comites() {
      try {
        this.raw_comite = (await api.obtener_comites_historicos(this.opciones)).data;
        this.comites = this.raw_comite.data;
        this.comite_visible = this.comites.length > 0 ? [0].id : null;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    async buscar_solicitud() {
      try {
        if (!this.buscar) {
          this.obtener_comites();
          return;
        }

        this.comites = (await api.buscar_comite(this.buscar)).data;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    class_solicitudes_show(comite) {
      return this.comite_visible == comite.id ? '' : ' icon-close-position';
    },
   paginacion_refrescar(opciones) {
    this.opciones = opciones;

    this.obtener_comites();
   },
    paginado_avanzar() {
      if (this.pagina_actual == this.total_paginas)
        return;

      this.$router.push({name: 'analyticpoint-historico', query: {page: (this.pagina_actual+1)}})
    },
    paginado_atrazar() {
      if (this.pagina_actual == 1)
        return;

      this.$router.push({name: 'analyticpoint-historico', parqueryams: {page: (this.pagina_actual-1)}})
    },
    paginado_saltar(pagina) {
      if (this.pagina_actual == pagina)
        return;

      this.$router.push({name: 'analyticpoint-historico', query: {page: pagina}})
    },
    definir_estatus_solicitud(solicitud) {
      if (!solicitud.resolucion)
        return '';

      return solicitud.resolucion == 'aceptado' ? ' solicitud_aprobada' : ' solicitud_rechazada';
    },
    async solicitar_minuta(comite) {
      try {
        let res = (await api.solicitar_minuta(comite.id)).data;
        this.$log.info('res', res);
        this.$helper.showMessage('Exito','Se ha solicitado la minuta del comite '+comite.numero,'succes','alert');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  },
  computed: {
    pagina_actual() {
      return this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    total_paginas() {
      return Math.ceil(this.comites.length / this.comites_pagina);
    },
    generar_num_paginas() {
      let inicio = this.pagina_actual == 1 ? 1 : (this.pagina_actual > 3 ? (this.pagina_actual - 3) : 1);
      let final = this.pagina_actual == this.total_paginas ? this.total_paginas : (this.pagina_actual < (this.total_paginas - 3) ? (this.pagina_actual + 3) : this.total_paginas);
      let paginas = [];

      for(let i=inicio; i<=final; i++) {
        paginas.push(i);
      }

      return paginas;
    },
    comites_paginados() {
      let inicio = this.comites_pagina * (this.pagina_actual-1);
      let final = inicio + this.comites_pagina;
      this.$log.info('paginado inicio: ',inicio);

      let comites = [];
      for(let i=inicio; i<final; i++) {
        if (this.comites[i])
          comites.push(this.comites[i]);
      }

      return comites;
    }
  }
}
</script>

<style lang="scss" scoped>
$solicityd_abierta: #0781cd;
$solicitud_votada: #0d8b22;
$solicitud_rechazada: #971212;
.comites {
  .comite {
    border:solid 1px hsl(0, 0%, 80%);
    border-radius: 3px;
    margin-bottom: 10px;
    vertical-align: middle;
    position: relative;

    .cabecera {
      background-color: hsl(0, 0%, 90%);

      .numero,.fecha_inicio,.fecha_fin,.num_solicitudes,.minuta,.accion {
        width: 18%;
        padding: 6px 8px;
        display: inline-block;

        span {
          // color: #fff;
          text-align: center;
          display: block;
        }

        span.title {
          font-weight: bold;
        }
      }
      
      .accion {
        width: 8%;
        text-align: center;
        position: absolute;
        top: 12px;
      }

      .icon-close-position {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .solicitudes {
      background-color: #fff;
      padding: 10px;
      padding-bottom: 0px;

      .solicitud {
        background-color: $solicityd_abierta;
        margin: auto;
        color: #fff;
        padding: 20px 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        display: flexbox;

        .id_solicitd,.nombre,.monto,.resolucion,.control {
          width: 10%;
          display: inline-block;
        }

        .tasa,.plazo,.aforo {
          width: 7%;
          display: inline-block;
        }

        .nombre {
          width: 44%;
        }

        .control {
          width: 5%;
          text-align: center;

          i {
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
      }

      .solicitud_aprobada {
        background-color: $solicitud_votada;
      }

      .solicitud_rechazada {
        background-color: $solicitud_rechazada;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .comites {
    .comite {
      .cabecera {
        .numero,.fecha_inicio,.fecha_fin,.num_solicitudes,.accion,.tasa,.plazo,.aforo {
          width: 100%;
          display: block;

          span {
            display: inline-block;
          }
          span.title {
            width: 60%;
            text-align: left;
          }

          span.value {
            width: 40%;
            text-align: right;
          }
        }
        .accion {
          position: static;
        }
      }

      .solicitudes {
        .solicitud {
          .id_solicitd,.nombre,.monto,.control,.tasa,.plazo,.aforo {
            width: 100%;
            text-align: right;
            display: block;

            strong {
              float: left;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 577px) and (max-width: 667px) {
  .comites {
    .comite {
      .solicitudes {
        .solicitud {
          .id_solicitd,.nombre,.monto,.control,.tasa,.plazo,.aforo {
            width: 30%;
          }

          .nombre {
            width: 45%;
          }

          .control {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: 1180px) {
  .comites {
    .comite {
      .solicitudes {
        .solicitud {
          .id_solicitd,.nombre,.monto,.control,.tasa,.plazo,.aforo {
            width: 20%;
          }

          .nombre {
            width: 50%;
          }

          .control {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>