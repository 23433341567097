import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_ANALYTICPOINT+'/comites');

export default {
  obtener_comite_activo(all) {
    let url = `/activos`;

    if (typeof all != 'undefined')
      url += '?all=1';
    
    return http.get(url);
  },

  obtener_comites() {
    return http.get(`/?order_col=created_at&order_dir=desc`);
  },

  obtener_comites_historicos(options) {
    return http.get(utils.url_options(`/historicos?display=frontend`, options));
  },

  buscar_comite(ids) {
    return http.get(`/search/${ids}?order_col=created_at&order_dir=desc`);
  },

  solicitar_minuta(id) {
    return http.get(`/${id}/minuta`);
  }
}
